import React from "react";
import SectionHead from "../components/generic/SectionHead";
import STEMHContent from "../components/STEMHContent";
import { findContent } from "../utils/searcher";
import "./Admission.css";

const Admission = () => {
  return (
    <div className="admission">
      <SectionHead title="Admission" />
      <STEMHContent data={findContent("admission_dates")} align="r" />
      <h1 className="admission_title">
        We assess an applicant's candidacy in three areas
      </h1>
      <STEMHContent data={findContent("admission_academics_ability")} />
      <STEMHContent data={findContent("admission_personal_qualities")} />
      <STEMHContent data={findContent("admission_fit")} />
      <STEMHContent data={findContent("admission_application")} />
      {/* <STEMHContent data={findContent("admission_documents")} /> */}
      <div className="admission_fees">
        <STEMHContent data={findContent("admission_fees")} align="r" />
      </div>
      <STEMHContent data={findContent("admission_payments")} />
      <STEMHContent data={findContent("admission_scholarships")} />
    </div>
  );
};

export default Admission;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import data from "../../data/navlinks.json"
import "./Navbar.css";

const Navbar = () => {
  const hideAfter = () => {
    setShowLinks(!showLinks);
  };

  const logo = process.env.PUBLIC_URL + "/images/STEM_logo.png";
  const [showLinks, setShowLinks] = useState(false);

  const navLinklItems = data.map((record) => {
    return (
      <li key={"nav_" + record.id}>
      <NavLink 
        className={record.nstyle}
        to={record.path}
        onClick={() => hideAfter()}
      >
        {record.label}
      </NavLink>
    </li>
    );
  });
  return (
    <div className="stem_navbar">
      <div className="left_bar">
        <NavLink to="/">
          <img
            className="logo rounded nav_logo"
            src={logo}
            alt="STEM High Logo"
          />
        </NavLink>
      </div>
      <div className="right_bar">
        <div className="menu_button">
          <IconButton aria-label="Open" onClick={() => hideAfter()}>
            <MenuIcon />
          </IconButton>
          <NavLink to="/" onClick={() => setShowLinks(false) }>STEM High by MexicoTech</NavLink>
        </div>
        <div className="search_bar">
          <input type="text" placeholder="Search..."></input>
          <IconButton aria-label="search">
            <SearchIcon />
          </IconButton>
        </div>
        <div className="nav_links" id={showLinks ? "" : "hidden"}>
          <ul>
            {navLinklItems}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

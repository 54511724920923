import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import data from "../../data/social_links.json";
import "./Socialbar.css";

/* 
https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react */
const Socialbar = () => {
    const socialButtons = data.map((record) => {
        return (
        <li key={record.name} className={record.name}>
          <a href={record.url} target={record.target}>
          <FontAwesomeIcon icon={['fab', record.icon]} size="lg"/>
          </a>
        </li>);
    });
  return (
    <div className="socialnets">
      <ul>
        {socialButtons}
      </ul>
    </div>
  );
};

export default Socialbar;

import React from "react";
import SectionHead from "../components/generic/SectionHead";
import STEMHContent from "../components/STEMHContent";
import { findContent } from "../utils/searcher";
import "./Education.css";

const Education = () => {
  return (
    <div className="education">
      <SectionHead title="Our Education Method" />
      <h1 className="education_title">STEM High School education methodology consists <br/>of two essential pillars:</h1>
      <STEMHContent data={findContent("education_seminars")} />
      <STEMHContent data={findContent("education_projects")} />
    </div>
  );
};

export default Education;

import React from "react";
import SectionHead from "../components/generic/SectionHead";
import STEMHContent from "../components/STEMHContent";
import { findContent } from "../utils/searcher";
import "./Academics.css";

const Academics = () => {
  const transImg = "/images/transdisciplinary_jigsaw.png";
  return (
    <div className="academics">
      <SectionHead title="Academics" />
      <STEMHContent data={findContent("academics_presentation")} />
      <div className="academic_paragraph">
        <STEMHContent data={findContent("academics_approach")} />
        <STEMHContent data={findContent("academics_equity")} subtitle="T" />
        <STEMHContent
          data={findContent("academics_individuality")}
          subtitle="T"
        />
        <STEMHContent data={findContent("academics_work")} subtitle="T" />
        <STEMHContent
          data={findContent("academics_collaborative")}
          subtitle="T"
        />
      </div>
      <div className="academic_paragraph">
        <STEMHContent data={findContent("academics_transdisciplinary")} />
        <img src={transImg} alt="transdisciplinary" />
      </div>
      <div className="academic_paragraph">
        <STEMHContent data={findContent("academics_curriculum")} />
        <STEMHContent data={findContent("academics_science")} subtitle="T" />
        <STEMHContent data={findContent("academics_math")} subtitle="T" />
        <STEMHContent data={findContent("academics_language")} subtitle="T" />
        <STEMHContent data={findContent("academics_history")} subtitle="T" />
        <STEMHContent data={findContent("academics_philosophy")} subtitle="T" />
      </div>
      <STEMHContent data={findContent("academics_ability")} />
      <div className="academic_paragraph">
        <STEMHContent data={findContent("academics_support")} align="r" />
      </div>
    </div>
  );
};

export default Academics;

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import parse from "html-react-parser";
import "./Gallery.css";

const Gallery = (props) => {
  const elements = props.data;

  const galleryItems = elements.map((record) => {
    var path = "/about/" + record.key;
    var galleryName = "<span className='gallery_name'>" + record.name +  "</span>";
    if (record.hasOwnProperty("title")) {
      galleryName += "<br/><span className='gallery_title'>" + record.title + "</span>";
    }
    return (
      <Col xs={12} sm={6} md={4} lg={3} key={"gallery_" + record.id}>
        <Card >
          <img src={record.image} alt={record.key} />
          <Card.Body>
            <Card.Text>{parse(galleryName)}</Card.Text>
            <Link to={path}>
              <Button variant="outlined">Background »</Button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    );
  });
  return (
    <div className="gallery">
      <Container>
        <Row>{galleryItems}</Row>
      </Container>
    </div>
  );
};

export default Gallery;

import { React, useState } from "react";
import { Carousel } from "react-bootstrap";
import parse from 'html-react-parser';
import "./ImageCarousel.css";

/**
 * Should recieive in props:
 *  - cstyle: To select what is the style sheet we need
 *  - data: The configuration of the images to display
 */

const ImageCarousel = (props) => {
  const data = props.data;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const carouselItems = data.map((record) => {
    var title = parse(record.title);
    var subtitle = parse(record.subtext);
    return (
      <Carousel.Item key={record.id}>
        <img className={record.img_style} src={record.image} alt={record.alt} />
        <Carousel.Caption>
          <div className={record.text_style}>
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });
  
  return (
    <div className={props.cstyle}>
    <Carousel activeIndex={index} onSelect={handleSelect} fade>
      {carouselItems}
    </Carousel>
    </div>
  );
}

export default ImageCarousel;

// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faFacebook,
    faWhatsapp,
    faTwitter,
    faInstagram,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(faFacebook, faWhatsapp, faTwitter, faInstagram, faYoutube);
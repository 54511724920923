import React from "react";
import SectionHead from "../components/generic/SectionHead";
import STEMHContent from "../components/STEMHContent";
import { findContent } from "../utils/searcher";
import "./StudentLife.css";

const StudentLife = () => {
    return (
      <div className="students_life">
        <SectionHead title="Student's Life" />
        <STEMHContent data={findContent("student_life")}  />
        <STEMHContent data={findContent("student_comunity")}  />
        <STEMHContent data={findContent("student_body")}  />
        </div>
  );
};

export default StudentLife;
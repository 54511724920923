import React from "react";
import ImgCarousel from "../components/generic/ImageCarousel";
import MsgCarousel from "../components/generic/MessageCarousel";
import Gallery from "../components/generic/Gallery";
import SectionHead from "../components/generic/SectionHead";
import STEMHContent from "../components/STEMHContent";
/* Data sources */
import mainCarousel from "../data/main_carousel.json";
import aboutCarousel from "../data/about_carousel.json";
import { findContent, getStaff } from "../utils/searcher";

const Home = () => {
  return (
    <div>
      <ImgCarousel cstyle="img-carousel" data={mainCarousel} />
      <MsgCarousel data={aboutCarousel} />
      <STEMHContent data={findContent("headmaster_msg")} />
      <SectionHead title="Welcome" />
      <Gallery data={getStaff()} />
    </div>
  );
};

export default Home;

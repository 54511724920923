import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import parse from "html-react-parser";
import "./STEMHContent.css";

/**
 * Highlighted element.
 *  - Data: entry from the stemh_content.json file
 *         * if image is missing, all will be used by the text
 *         * if link missing, it is not shown.
 *         * if image_desc missing, no frame will be shown
 *  - Align: 'l' or none - Image on left
 *           'r' - Image on right
 */
const ContentMedia = (props) => {
  const record = props.data;
  if (record.hasOwnProperty("image") && record.image !== "") {
    return <ContentImage {...props} />;
  } else if (record.hasOwnProperty("video") && record.image !== "") {
    return <ContentVideo {...props} />;
  }
  return "";
};

const ContentImage = (props) => {
  const record = props.data;
  if (record.hasOwnProperty("image") && record.image !== "") {
    var description =
      record.hasOwnProperty("image_desc") && record.image_desc !== ""
        ? parse("<p>" + record.image_desc + "</p>")
        : "";
    var imgClass =
      record.hasOwnProperty("image_desc") && record.image_desc !== ""
        ? "portrait"
        : "stock";
    return (
      <Col xs={12} sm={12} md={4} className={imgClass}>
        <img src={record.image} alt={record.key} />
        {description}
      </Col>
    );
  }
  return "";
};

const ContentVideo = (props) => {
  const record = props.data;
  if (record.hasOwnProperty("video") && record.image !== "") {
    return (
      <Col xs={12} sm={12} md={4}>
        <video width="320" height="240" autoPlay muted loop>
          <source src={record.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Col>
    );
  }
  return "";
};

/**
 * Listas de contenido. Aparecerán si en el entry tienen la propiedad "list"
 *  - list_style: (opcional) Puede tener el valor ordered o ninguno,
 *                para ver si es lista con numeros o con marcas.
 *  - list_desc: (opcional) Lo que aparezca aqui sera como pie de nota bajo la lista.
 *
 * @param {*} props
 * @returns
 */
const ContentList = (props) => {
  const record = props.data;
  if (record.hasOwnProperty("list") && record.list !== "") {
    var entries = record.list;
    var ltype =
      record.hasOwnProperty("list_style") && record.list_style !== ""
        ? record.list_style
        : "unordered";
    var keyVal = record.key + "_" + record.id + "_list_";
    var count = 1;
    const listItems = entries.map((entry) => {
      return (
        <li key={keyVal + count++}>
          <div className="content_list_item">{parse(entry)}</div>
        </li>
      );
    });
    var listDesc =
      record.hasOwnProperty("list_desc") && record.list_desc !== ""
        ? parse(
            "<div className='content_list_desc'>" + record.list_desc + "</div>"
          )
        : "";
    if (ltype === "unordered") {
      return (
        <div className="content_list">
          <ul>{listItems}</ul>
          {listDesc}
        </div>
      );
    }
    return (
      <div className="content_list">
        <ol>{listItems}</ol>
        {listDesc}
      </div>
    );
  }
  return "";
};

const ContentButton = (props) => {
  const record = props.data;
  if (record.hasOwnProperty("link") && record.link !== "") {
    var path = "/about/" + record.link;
    var buttonText = record.link_label !== "" ? record.link_label : "More";
    return (
      <div className="content_link">
        <Link to={path}>
          <Button variant="outlined" endIcon={<LightbulbIcon />}>
            {buttonText}
          </Button>
        </Link>
      </div>
    );
  }
  return "";
};

const ContentText = (props) => {
  const record = props.data;
  const hSize =
    props.hasOwnProperty("subtitle") && props.subtitle !== "" ? "h3" : "h2";
  var title =
    record.title !== ""
      ? parse("<" + hSize + ">" + record.title + "</" + hSize + ">")
      : "";
  var content =
    record.hasOwnProperty("content") && record.content !== ""
      ? parse(record.content)
      : "";
  var colWidth =
    (record.hasOwnProperty("image") && record.image !== "") ||
    (record.hasOwnProperty("video") && record.video !== "")
      ? 8
      : 12;
  return (
    <Col xs={12} sm={12} md={colWidth}>
      <div className="content_text">
        {title}
        {content}
        <ContentList {...props} />
        <ContentButton {...props} />
      </div>
    </Col>
  );
};

/**
 * This is the main content display object. This is configurable from the json file with the
 * appropriate texts. Two parameters can be sent:
 *    - data: (mandatory) The entry from the json file
 *    - align: (optional) If there is an image we can state if we want it left or right.
 *             Default left.
 *    - subtitle: (optional) IF present, with any value, the title will become subtitle.
 *
 * The entries sent through the parameter can contain:
 *    - id: Id of the entry
 *    - key: Identification key of the entry
 *    - title: Title that will be displayed
 *    - image: The image that will be shown in the object
 *    - image_desc: A subtext for the image
 *    - content: The main text to be displayed
 *    - link: The link we will be redirecting to
 *    - link_label: The text that will be shown of the link
 *    - style: highlight or regular_text will show the content with other style.
 *             Default regular_text.
 *
 * @param {*} props
 * @returns
 */
const STEMHContent = (props) => {
  const record = props.data;
  var cstyle =
    record.hasOwnProperty("style") && record.style !== ""
      ? record.style
      : "regular_text";
  if (props.align === "r") {
    return (
      <div className="stemh_content r">
        <Container className={cstyle}>
          <Row>
            <ContentText {...props} />
            <ContentMedia {...props} />
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="stemh_content l">
        <Container className={cstyle}>
          <Row>
            <ContentMedia {...props} />
            <ContentText {...props} />
          </Row>
        </Container>
      </div>
    );
  }
};

export default STEMHContent;

import { React, useState } from "react";
import { Carousel } from "react-bootstrap";
import parse from "html-react-parser";
import "./MessageCarousel.css";

/**
 * Should recieive in props:
 *  - data: The configuration of the images to display
 */

const MessageCarousel = (props) => {
  const data = props.data;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const carouselItems = data.map((record) => {
    var title = parse(record.title);
    var content = parse(record.content);
    return (
      <Carousel.Item key={record.id} interval={record.interval * 1000}>
        <img className={record.img_style} src={record.image} alt={record.alt} />
        <Carousel.Caption>
          <div className={record.text_style}>
            <h3>{title}</h3>
            {content}
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    );
  });

  return (
    <div className="message_carousel">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {carouselItems}
      </Carousel>
    </div>
  );
};

export default MessageCarousel;

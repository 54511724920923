import React from "react";
import "./Footer.css";

const Footer = () => {
  const mex_tech_logo = process.env.PUBLIC_URL + "/images/mexicotech_logo.png";
  const arx_logo = process.env.PUBLIC_URL + "/images/arx-logo.png";

  return (
    <div className="stem_footer">
      <div className="left_foot">
        <a href="http://mexicotech.education/">
          <img src={mex_tech_logo} alt="Mexico Tech Logo" />
        </a>
      </div>
      <div className="mid_foot">
        <p>
          Mexico City Institute of Technology (MexicoTech) is a nonprofit,
          independent private research and education organization<br /> based in
          Mexico City.
        </p>
        <hr />
        <p>
          Todos los derechos reservados
          <br /> Mexico City Institute of Technology © Copyright 2019
        </p>
      </div>
      <div className="right_foot">
        <a href="http://arx.academy/">
          <img src={arx_logo} alt="Arx Logo" />
        </a>
      </div>
    </div>
  );
};

export default Footer;

import React from 'react'
import "./SectionHead.css";

const SectionHead = (props) => {
    const title = props.title;
    return (
        <div className="section_head">
            <h1><span>{title}</span></h1>
        </div>
    )
}

export default SectionHead

import content from "../data/stemh_content.json";
import faculty from "../data/faculty.json";
import staff from "../data/staff.json";

import { getEntry, sortArray } from "./utilities";

function findContent(key) {
    var entry = getEntry(content, key);
    if (!entry) {
        return { "message": "No entry available" }
    }
    return entry;
}

function findPerson(key) {
    var entry = getEntry(faculty, key);
    if (!entry) {
        entry = getEntry(staff, key);
    }
    if (!entry) {
        return { "message": "No entry available" }
    }
    return entry;
}

function getStaff() {
    return sortArray(staff, "id");
}

function getFaculty() {
    return sortArray(faculty, "id");
}

export { findContent, findPerson, getStaff, getFaculty };
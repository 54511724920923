/* Finds the element that contains the key from the array */
function getEntry(arr, key) {
    const record = arr.find(item => item.key === key);
    return record;
}

function compare(key, order = 'asc') {
    return function innerSort(obj1, obj2) {
        if (!obj1.hasOwnProperty(key) || !obj2.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof obj1[key] === 'string') ?
            obj1[key].toUpperCase() : obj1[key];
        const varB = (typeof obj2[key] === 'string') ?
            obj2[key].toUpperCase() : obj2[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

function sortArray(array, field) {
    return array.sort(compare(field));
}

export { getEntry, sortArray };
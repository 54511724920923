import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/generic/Navbar";
import Footer from "./components/generic/Footer";
import SocialBar from "./components/social/Socialbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Academics from "./pages/Academics";
import Admission from "./pages/Admission";
import Education from "./pages/Education";
import Faculty from "./pages/Faculty";
import StudentLife from "./pages/StudentLife";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

function App() {
  return (
    <div className="stemhigh_app">
      <Router>
        <Navbar />
        <SocialBar />
        <Routes>
          <Route path="/" element={<Home />} />{" "}
          <Route path="/about/:key" element={<About />} />{" "}
          <Route path="/academics" element={<Academics />} />{" "}
          <Route path="/admission" element={<Admission />} />{" "}
          <Route path="/education" element={<Education />} />{" "}
          <Route path="/faculty" element={<Faculty />} />{" "}
          <Route path="/student_life" element={<StudentLife />} />{" "}
        </Routes>{" "}
        <Footer />
      </Router>{" "}
    </div>
  );
}

export default App;

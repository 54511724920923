import React from "react";
import Gallery from "../components/generic/Gallery";
import SectionHead from "../components/generic/SectionHead";
import STEMHContent from "../components/STEMHContent";
import { getFaculty, findContent } from "../utils/searcher";
import "./Faculty.css";

const Faculty = () => {
  return (
    <div className="faculty">
      <SectionHead title="Faculty" />
      <STEMHContent data={findContent("faculty_desc")} />
      <Gallery data={getFaculty()} />
    </div>
  );
};

export default Faculty;

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Button } from "@mui/material";
import { findPerson } from "../utils/searcher";
import parse from "html-react-parser";
import "./About.css";

const About = (props) => {
  const history = useNavigate();
  const { key } = useParams();
  const record = findPerson(key);
  var background = parse(record.background);

  return (
    <div className="background">
      <Container>
        <Row>
          <Col xs={12} sm={12} md={4}>
            <Card>
              <img src={record.image} alt={record.key} />
              <Card.Body>
                <Card.Text>{record.name}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={8}>
            <h3>{record.name}</h3>
            <p><i> &#8212; Background</i></p>
            <div className="background_text">
            {background}<br/>
            <Button
                variant="outlined"
                onClick={() => history(-1)}
              >
                &laquo; Go Back
              </Button> 
            </div>
           
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
